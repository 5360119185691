<template>
  <div class="masonry mt-8">
    <div
      class="grid_masonry"
      v-for="(item, index) in datos[obra]"
      :key="index"
      @click="showLightbox(item.name)"
    >
      <img
        :src="directorioThumbs + item.name"
        :alt="item.title"
        :key="index"
        loading="lazy"
      />
      <div class="grid__body">
        <!--<div class="relative" v-if="tipo !== 'obras'">
          <h1 class="grid__title" v-text="item.title"></h1>
          <p class="grid__author" v-text="item.subtitulo"></p>
        </div>-->
      </div>
    </div>
    <lightbox
      id="mylightbox"
      ref="lightbox"
      :directory="directorioObras"
      :images="datos[obra]"
      :timeoutDuration="5000"
    ></lightbox>
  </div>
</template>

<script>
import Lightbox from "vue-my-photos";

export default {
  name: "Masonry",
  props: ["obra", "datos", "tipo"],
  components: {
    Lightbox
  },
  data: function() {
    return {
      directorioObras:
        "https://cabubi.sfo2.cdn.digitaloceanspaces.com/brg/img/full",
      directorioThumbs:
        "https://cabubi.sfo2.cdn.digitaloceanspaces.com/brg/img/thumbs"
    };
  },
  methods: {
    showLightbox: function(imageName) {
      console.log(imageName);
      this.$refs.lightbox.show(imageName);
    }
  }
};
</script>

<style scoped>
/* Masonry - https://codepen.io/93lucasp/pen/LYYMYoB */
.masonry {
  columns: 4;
  column-gap: 16px;
}
@media (max-width: 1200px) {
  .masonry {
    columns: 3;
  }
}
@media (max-width: 992px) {
  .masonry {
    columns: 2;
  }
}

@media (max-width: 361px) {
  .masonry {
    columns: 1;
  }
}

.masonry .grid_masonry {
  display: inline-block;
  margin-bottom: 16px;
  position: relative;
  cursor: pointer;
}
.masonry .grid_masonry:before {
  border-radius: 5px;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
}
.masonry .grid_masonry img {
  width: 100%;
  border-radius: 5px;
}
.masonry .grid__title {
  font-size: 28px;
  font-weight: bold;
  font-family: "Oxygen", serif;
  margin: 0 0 10px 0;
  text-align: left;
}
.masonry .grid__author {
  font-size: 14px;
  font-weight: 300;
  font-family: "Oxygen", serif;
  text-align: left;
}

.masonry .grid__body {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 30px 30px;
  color: #fff;
  display: flex;
  flex-direction: column;
}
/* Fin Masonry */
</style>
