<template>
  <div class="grid grid-cols-1 p-5 my-5 bg-white">
    <vue-breadcrumb :breadcrumbs="$route.name" />
    <vue-masonry obra="critica" :datos="info.criticas" :tipo="criticas" />
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import Masonry from "@/components/Masonry";
import { mapActions, mapState } from "vuex";

export default {
  name: "Criticas",
  props: {
    obra: String
  },
  components: {
    "vue-breadcrumb": Breadcrumb,
    "vue-masonry": Masonry
  },
  computed: {
    ...mapState(["info"])
  },
  methods: {
    ...mapActions(["getInfo"])
  },
  mounted() {
    this.getInfo();
  }
};
</script>

<style scoped></style>
